<!--
Below button is used for removing the focus from the list. 
-->

<button id="button-hide" #hiddenButton style="position: absolute; opacity: 0; pointer-events: none"></button>

<div
	*ngIf="{
		user: user$ | async
	} as vm"
	class="px-3 py-4"
>
	<div>
		<!-- Avatar -->
		<div *ngIf="vm.user" class="header profile mh-200">
			<img [ngSrc]="vm.user?.picture ? vm.user.picture : defaultAvatar" alt="user profile picture" width="40" height="40" class="img-profile" />

			<div class="mw-200px">
				<span class="text-body-regular fg-general-5 d-block text-truncate">{{ vm.user.name }}</span>
				<span class="text-caption-regular fg-general-5 text-truncate text-wrap">{{ vm.user.email }}</span>
			</div>
		</div>

		<!-- FindQo brand name -->
		<span *ngIf="!vm.user" class="brand-name brand-position">{{ 'n3786' | translations }}</span>
	</div>
	<p *ngIf="!vm.user" class="text-body-regular fg-general-4 mt-3 text-center">{{ 'n3252' | translations }}</p>

	<!-- Login -->
	<findqo-button
		*ngIf="!vm.user"
		class="d-block mt-3"
		btnClass="w-100 rounded-corner"
		[label]="'n11' | translations"
		[btnType]="buttonType.FILLED"
		(clicked)="onLogin()"
	></findqo-button>

	<mat-nav-list class="p-0">
		<!-- Account nav list -->
		<div class="border-bottom py-3">
			<ng-template
				*ngFor="let nav of vm.user | navMenuItems: true : isAgentHub : true : viewportService"
				[ngTemplateOutlet]="listItemTemplate"
				[ngTemplateOutletContext]="{ $implicit: nav }"
			></ng-template>
		</div>

		<!-- Logout -->
		<div *ngIf="vm.user" class="border-bottom border-top py-3">
			<mat-list-item class="logout" (click)="onLogout()">
				<mat-icon matListItemIcon class="material-icons-outlined fg-general-4">logout</mat-icon>
				<span matListItemTitle class="text-body-regular fg-general-5">{{ 'n14' | translations }}</span>
			</mat-list-item>
		</div>

		<!-- Other nav list -->
		<div class="py-3">
			<ng-template *ngFor="let nav of otherNavList" [ngTemplateOutlet]="listItemTemplate" [ngTemplateOutletContext]="{ $implicit: nav }"></ng-template>
		</div>
	</mat-nav-list>
</div>

<!-- Nav item -->
<ng-template #listItemTemplate let-nav>
	<mat-list-item *ngIf="nav.data?.newTab" class="ps-0">
		<a class="social-icons fg-general-3" [href]="nav.path" target="_blank">
			<mat-icon class="material-symbols-outlined icon-size">feedback</mat-icon>
			{{ 'n3278' | translations }}
		</a>
	</mat-list-item>

	<mat-list-item [routerLink]="nav.path" [queryParams]="nav?.queryParams" (click)="offcanvas.dismiss()" *ngIf="!nav.data?.newTab">
		<img
			matListItemIcon
			*ngIf="nav.data.iconImage"
			[ngSrc]="'assets/images/' + nav.data.iconImage + '.svg'"
			width="18"
			height="18"
			alt="business profile"
			class="m-0 me-2 icon-image"
		/>
		<mat-icon matListItemIcon [ngClass]="nav.data?.isOther ? 'fg-general-3 material-symbols-outlined' : 'fg-general-4'" *ngIf="nav.data.icon">
			{{ nav.data.icon }}
		</mat-icon>

		<span
			*ngIf="!nav.data.isPopup"
			matListItemTitle
			class="text-body-regular"
			[ngClass]="nav.data?.isOther ? 'fg-general-3' : nav.data?.isSubmenu ? 'fg-general-4' : 'fg-general-5'"
		>
			{{ nav.data.title | translations }}
			<span *ngIf="nav.data?.isNew" class="bg-prim-2 px-2 rounded fg-basic-1 text-caption-medium ms-2">NEW!</span>
		</span>

		<findqo-button
			*ngIf="nav.data.isPopup"
			id="intercom-chat"
			class="fg-general-3 btn-help"
			[btnType]="buttonType.BASIC"
			[label]="'n3073' | translations"
			[btnClass]="'fg-general-3'"
			(clicked)="showIntercom()"
		></findqo-button>
	</mat-list-item>
</ng-template>
